//let[bgColorMain, colorMain] = document.querySelector("body").getAttribute("data-color").split(" ");

const colorsArray = [
  '#FF5733', // Color 1
  '#33FF57', // Color 2
  '#3357FF', // Color 3
  '#F1C40F', // Color 4
  '#8E44AD', // Color 5
  '#E67E22'  // Color 6
];

let coloredSections = gsap.utils.toArray("[data-color]");
coloredSections.forEach((section, i) => {
  // grab the colors from the attribute
  let [bgColor, color] = section.getAttribute("data-color").split(" ");
 
  let navColor = colorsArray[Math.floor(Math.random() * colorsArray.length)];

  let height = section.clientHeight
  ScrollTrigger.create({
    trigger: section,
    start: "top center",
    end: () => `${height}px`,
   //markers: true,
    onToggle: self => {
      // whenever we enter a section from either direction (scrolling up or down), animate to its color
      if (self.isActive) {
        gsap.to("section", {
          backgroundColor: bgColor,
          color: color,
          overwrite: "auto",
        });
        gsap.to(".bg-change", {
          backgroundColor: bgColor,
          color: color,
          overwrite: "auto",
        });
        gsap.to(".navbar-brand .btn-logo .btn-cube span:nth-child(1)", {
          backgroundColor: navColor,
          //color: color,
          overwrite: "auto",
        });
        
      // when we LEAVE the very first section scrolling in reverse -OR- when we scroll past the very last section (forward), return to the "normal" colors
      } else if ((i === 0 && self.direction < 0) || (i === coloredSections.length - 1 && self.direction > 0)) {
        gsap.to("section", {
          backgroundColor: bgColor,
          color: color,
          overwrite: "auto"
        });
        gsap.to(".bg-change", {
          backgroundColor: bgColor,
          color: color,
          overwrite: "auto",
        });
      }
    }
  });
});

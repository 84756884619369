if (document.querySelector(".down") != null) {
gsap.to(".down", {  
  //borderRadius: "30px",
  //margin: "16px",
  scale: .95,  
  ease: "sine.out", 
  //borderBottomRightRadius: "70% 35%",
  //borderBottomLeftRadius: "70% 35%",
  scrollTrigger: { 
    trigger: ".down",
    start: "bottom bottom",
    end: "bottom center",
    scrub: true
  } 
});
}
if (document.querySelector(".up") != null) {          
gsap.to(".up", {
  scale: .95,
  //margin: "0",
  ease: "sine.out",
  scrollTrigger: {
    trigger: ".up",
    start: "top top",
    end: "bottom bottom-=300px",
    scrub: true 
    // markers: { startColor: "blue", endColor: "orange" }
  }
});
} 

if (document.getElementById("clientSticky") != null) {

  // // Select all elements with the class "landscape-text"
  // let reveal = document.querySelectorAll(".landscape-text");
  // // Iterate over each selected element
  // gsap.set(".landscape-text__heading", {  autoAlpha: 0 });
  // gsap.set(".landscape-text__content", {  autoAlpha: 0 });
  
  // reveal.forEach((el) => {
  //   let id = el.getAttribute('data-id');
  //   console.log(id);
  //   let heading = el.querySelector('.landscape-text__heading');
  //   let content = el.querySelector('.landscape-text__content');
  //   let slide = document.querySelector(`.landscape-nav__item[data-id='${id}']`);
    
  //   let triggerEl = document.querySelector(`.landscape-image[data-id='${id}']`);
  //   let height = document.querySelector(`.landscape-image[data-id='${id}']`).clientHeight
  //   console.log(triggerEl);
  //   console.log(height);
  
    
  //   // Create ScrollTrigger for the animation
  //   ScrollTrigger.create({
  //     trigger: triggerEl,
  //     start: 'top center',
  //     end: () => `${height/2}px`,
  //     //markers: true,
  //     toggleClass: {targets: el, className: "wipe"},
  //     toggleActions: "play none none reverse ",
  //         onEnter: function () {
  //       console.log('enter');
  //       gsap.to(heading, {
  //         y: 0, autoAlpha: 1, duration: .4,  delay: .35,  stagger: .01, ease: "power2.inOut"
  //      });
  //      gsap.to(content, { 
  //       y: 0, autoAlpha: 1, duration:1, delay:.5
  //    });
  //    gsap.to(slide, { 
  //     y: 0, autoAlpha: 1, duration:1, delay:.5
  //  });
  //     },
  //     onLeave: function () {
  //       console.log('leave');
  //       gsap.to(heading, {
  //          y: 0, autoAlpha: 0 , duration:1
  //       });
  //       gsap.to(content, {
  //         y: 0, autoAlpha: 0, duration:1
  //      });
  //     },
  //     onEnterBack: function () {
  //       console.log('enterback');
  //       gsap.to(heading, {
  //         y: 0, autoAlpha: 1, duration:1, delay:.5
  //      });
  //      gsap.to(content, {
  //       y: 0, autoAlpha: 1, duration:1, delay:.5
  //    });
  //     },
  //     onLeaveBack: function () {
  //       console.log('leaveback');
  //       gsap.to(heading, {
  //         y: 0, autoAlpha: 0 , duration:1
  //      });
  //      gsap.to(content, {
  //       y: 0, autoAlpha: 0, duration:1
  //    });
  //     }
  //   });
  // });
  
  let reveal = document.querySelectorAll(".landscape-image");
  reveal.forEach((el) => {
    let id = el.getAttribute('data-id');
    let height = document.querySelector(`.landscape-image[data-id='${id}']`).clientHeight;
    let text = document.querySelector(`.landscape-text[data-id='${id}']`);
    let slide = document.querySelector(`.landscape-nav__item[data-id='${id}'] span`);
    //let navbar = document.querySelector('.navbar-brand');
   // Create GSAP animation for the slide and navbar rotation
   let animation = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top center',
      end: () => `${height / 2}px`,
      // Uncomment this line to see scroll triggers in action
      // markers: true,
      toggleClass: { targets: text, className: "wipe" },
      toggleActions: "play reverse play reverse"
    }
  });

  // Add animations to the timeline
  animation.to(slide, {
    width: "100%",
    transformOrigin: "left center", 
    ease: "none"
  }) 
  });
};
 


// gsap.registerPlugin(InertiaPlugin);

// if (document.querySelector(".slides-container")) {
//   var slidesContainer = document.querySelector(".slides-container");

//   var slides = document.querySelectorAll(".slide");
//   var numSlides = slides.length;

//   if (numSlides >= 2) {
//     var slideDelay = 1.5;
//     var slideDuration = 0.3;
//     var wrap = true;
  
//   var slideContent = document.querySelectorAll(".slide-content");
//   var prevButton = document.querySelector("#prevButton");
//   var nextButton = document.querySelector("#nextButton");
//   var progressWrap = gsap.utils.wrap(0, 1);


 
  
//   gsap.set(slides, {
//     xPercent: i => i * 100,
//   });

//   var wrapX = gsap.utils.wrap(-100, (numSlides - 1) * 100);

//   if (slidesContainer.hasAttribute("data-autoplay")) {
//     var timer = gsap.delayedCall(5, autoPlay);
//   } else {
//     var timer = gsap.delayedCall(1.5);
//   } 

//   var animation = gsap.to(slides, {
//     xPercent: "+=" + (numSlides * 100),
//     duration: 1,
//     inertia: true,
//     ease: "none",
//     paused: true,
//     repeat: -1,
//     modifiers: {
//       xPercent: wrapX
//     }
//   });

//   var proxy = document.createElement("div");
//   var slideAnimation = gsap.to({}, {});
//   var opacityAnimation = gsap.to({}, {}); // Add this line for opacity animation
//   var starAnimation = gsap.to({}, {});
//   var slideWidth = 0;
//   var wrapWidth = 0;

//   resize();

//   window.addEventListener("resize", resize);
//   if (slidesContainer.hasAttribute("data-controls")) {
//   prevButton.addEventListener("click", function () {
//     console.log('previous');
//     animateSlides(1);
//   });

//   nextButton.addEventListener("click", function () {
//     console.log('next');
//     animateSlides(-1);
    
   
//   });
//   }
//   function updateDraggable() {
//     timer.restart(true);
//     slideAnimation.kill();
//     opacityAnimation.kill(); // Kill opacity animation
//     starAnimation.kill();
//     this.update();
//   }

//   function animateSlides(direction) {
//     timer.restart(true);
//     slideAnimation.kill();
//     opacityAnimation.kill(); // Kill opacity animation
//     starAnimation.kill();

//     var x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);

//     slideAnimation = gsap.to(proxy, {
//       x: x,
//       duration: slideDuration,
//       onUpdate: updateProgress,
//       ease: "power1.out",
//     });

//     starAnimation = gsap.fromTo('.bi-star-fill' , { opacity: 0, x:-10,  }, {duration: slideDuration, x: 0, opacity:1, stagger: 0.1,  ease: "sine.out",});

//     // Update opacity animation
//     opacityAnimation = gsap.fromTo(slideContent, {
//       opacity: 0
//     }, {
//       opacity: 1,
//       duration: slideDuration,
//       stagger: 0.1, // Add some delay between elements if needed
//       ease: "back.out(1)",
      
//     });
//   }

 
//   function autoPlay() {  
//     if (slidesContainer.hasAttribute("data-autoplay")) {
//       animateSlides(-1);
//     }
//   }


//   function updateProgress() {
//     animation.progress(progressWrap(gsap.getProperty(proxy, "x") / wrapWidth));
//   }

//   function snapX(value) {
//     let snapped = gsap.utils.snap(slideWidth, value);
//     return wrap ? snapped : gsap.utils.clamp(-slideWidth * (numSlides - 1), 0, snapped);
//   }

//   function resize() {
//     var norm = (gsap.getProperty(proxy, "x") / wrapWidth) || 0;

//     slideWidth = slides[0].offsetWidth;
//     wrapWidth = slideWidth * numSlides;

//     wrap || draggable.applyBounds({ minX: -slideWidth * (numSlides - 1), maxX: 0 });

//     gsap.set(proxy, {
//       x: norm * wrapWidth
//     });

//     animateSlides(0);
//     slideAnimation.progress(1);
//   }
   
// }
// }
document.querySelectorAll(".wrapper-new-slide").forEach((container) => {
  // loop through all slider .wrappers
  console.clear();

 
  const sliders = gsap.utils.toArray(".slider-new-slide", container); // scope to current .wrapper
  const slidesArray = sliders.map((slider) =>
    gsap.utils.toArray(".slide-new-slide", slider)
  );
  const next = container.querySelector("#next"); // scope to current .wrapper
  const prev = container.querySelector("#prev"); // scope to current .wrapper
  let currentIndex = 0;
  let isTweening = false;

  // Autoplay settings
  const autoplayInterval = 10000; // 3 seconds
  let autoplayTimer;

  slidesArray.forEach((slides) => {
    slides.forEach((slide, i) => {
      gsap.set(slide, {
        xPercent: i > 0 && 100
      });
    });
  });

  const gotoSlide = (value) => {
    if (isTweening) return;
    isTweening = true;
    const first = slidesArray[0];
    const currentSlides = [];
    const nextSlides = [];
    slidesArray.forEach((slides) => currentSlides.push(slides[currentIndex]));
    if (first[currentIndex + value]) {
      currentIndex += value;
    } else {
      currentIndex = value > 0 ? 0 : first.length - 1;
    }
    slidesArray.forEach((slides) => nextSlides.push(slides[currentIndex]));
    if (value > 0) {
      gsap.set(nextSlides, { xPercent: 100 });
      gsap.to(currentSlides, {
        xPercent: -100,
        onComplete: () => (isTweening = false)
      });
    } else {
      gsap.set(nextSlides, { xPercent: -100 });
      gsap.to(currentSlides, {
        xPercent: 100,
        onComplete: () => (isTweening = false)
      });
    }
    gsap.to(nextSlides, { xPercent: 0 });
  };

  const startAutoplay = () => {
    autoplayTimer = setInterval(() => gotoSlide(1), autoplayInterval);
  };

  const stopAutoplay = () => {
    clearInterval(autoplayTimer);
  };

  // Start autoplay
  startAutoplay();

  next.addEventListener("click", () => {
    gotoSlide(1);
    stopAutoplay(); // Stop autoplay on user interaction
    startAutoplay(); // Restart autoplay
  });
  prev.addEventListener("click", () => {
    gotoSlide(-1);
    stopAutoplay(); // Stop autoplay on user interaction
    startAutoplay(); // Restart autoplay
  });
});

import gsap from '../../js/gsap/gsap.min.js';
import { ScrollTrigger } from '../../js/gsap/ScrollTrigger.min.js';
import "../../js/theme/_lenis.js";
import "../../js/theme/_colorsection.js";
import "../../js/theme/_clientsticky.js";
import "../../js/theme/_newsticker.js"; 
import "../../js/theme/_postlist.js"; 
import "../../js/theme/_stackingcards.js";
import "../../js/theme/_slider.js";
import "../../js/theme/_scrollradius.js";
 // Refreshes all ScrollTriggers allowing you to get the below values



 if (document.querySelector(".circle-overlay") !== null) {

const content = document.querySelector(".content");
const link = document.querySelector("a");
const linkIcon = document.querySelector(".btn-icon");
let linkAnimated = false;

let xTo = gsap.quickTo(".hidden-content", "--x", {
    duration: 0.4,
    ease: "power4.out"
  }),
  yTo = gsap.quickTo(".hidden-content", "--y", {
    duration: 0.4,
    ease: "power4.out"
  });

let tlHideContent = gsap.timeline({ paused: true });
tlHideContent.to(".hidden-content", {
  "--size": 250,
  duration: 0.75,
  ease: "back.out(1.7)"
});

let hoveringContent = gsap.utils.toArray(".rotate-title", content);

hoveringContent.forEach((el) => {
  el.addEventListener("mouseenter", () => {
    tlHideContent.restart();
  });
  el.addEventListener("mouseleave", () => {
    tlHideContent.reverse();
  });
});


window.addEventListener("mousemove", onFirstMove);

function onFirstMove(e) {
   
  window.removeEventListener("mousemove", onFirstMove);
  gsap.set(".hidden-content", { autoAlpha: 1, "--x": e.offsetX, "--y": e.offsetY });

  window.addEventListener("mousemove", (e) => {
   //console.log(e);
    if (!linkAnimated) {
      yTo(e.y);
      xTo(e.x);
    }
  });
}

/***************************************
      Only for the preview image
***************************************/
// gsap.set(".hidden-content", {
//   autoAlpha: 1,
//   "--x": window.innerWidth / 3,
//   "--y": window.innerHeight / 2
// });
tlHideContent.progress(0.2);

var container = document.querySelector(".circle-overlay");
var mask = document.querySelector(".mask");
var maskContent = document.querySelector(".mask-content");

window.addEventListener("load", init);

function init() {
  
  TweenLite.set(maskContent, {
    width: container.offsetWidth,
    height: container.offsetHeight
  });
  
  container.addEventListener("mousemove", onMove);
}

function onMove(e) {
    var rect = container.getBoundingClientRect();  
    var relX = e.pageX - container.offsetLeft - 100;
    var relY = e.pageY - container.offsetTop - 100;
  
   
  
  // var x = e.pageX - 100;
  // var y = e.pageY - 100;

  TweenLite.set(mask, {
    x: relX,
    y: relY
  });

  TweenLite.set(maskContent, {
    x: -relX,
    y: -relY
  });
}
  
  }

   
if (document.querySelector(".ClientCard_client-card__thumbnail-outer") != null) {
const section = document.querySelector('.ClientCard_client-card__thumbnail-outer');
const img = document.querySelector('.ClientCard_client-card__thumbnail-image');
gsap.fromTo(img, {
  y: () => section.offsetHeight - img.offsetHeight, 
}, {      
  scrollTrigger: {
    trigger: section,
    scrub: 0.5,
    invalidateOnRefresh: true
  },
  y: 0,
  ease: "none"
});
}

ScrollTrigger.refresh();
 
if (document.querySelector(".data") != null) {  
   
const items = document.querySelectorAll(".data");
gsap.from('.data', {
  textContent: 0,
  duration: 1,
  ease: Power1.easeIn,
  snap: { textContent: 1 },
  stagger: 1,
  scrollTrigger: {
    start: 'top bottom',
    trigger: items, 
   //markers: true
  } 
});
}    
    
if (document.querySelector(".rotate_text") != null) {  
  const wordContainer = document.querySelector(".word-container");
  const words = gsap.utils.toArray(".rotating-word");
  let maxWidth = 0;   
  const tl = gsap.timeline({
    repeat: -1
  });
  gsap.set(words, {
    yPercent: (i) => i && 100,    
    opacity: 1
  });
  words.forEach((word, i) => {
    const next = words[i + 1];
    if (next) {
      tl.to(word, { yPercent: -100 }, "+=2").to(next, { yPercent: 0 }, "<");
    } else {
      // Final word
      tl.to(word, { yPercent: -100 }, "+=2").fromTo(
        words[0],
        {
          yPercent: 100
        },
        {
          yPercent: 0,
          immediateRender: false
        },
        "<"
      );
    }
  });

} 

 
const menutl = gsap.timeline({ reversed: true, paused: true })
.to(".menu-wrapper-expand", { width: "auto",})
.to(".menu-wrapper-expand", { height: "auto", width: "auto", duration:.3, ease: [.86, 0, .07, 1]});

  
// Select the menu item and the menu wrapper for later use
const menuItem = document.getElementById("expertiseMenu");
const menuWrapper = document.querySelector(".menu-wrapper-expand");

// Add click event listener to the menu item
menuItem.addEventListener("click", (event) => {
  event.stopPropagation(); // Prevent the click event from propagating to the document
  toggle();
});

// Add click event listener to the document
document.addEventListener("click", () => {
  if (!menutl.reversed()) {
    menutl.reverse();
  }
});
window.addEventListener("scroll", () => {
  // You can adjust the scroll position threshold as needed
  const scrollThreshold = 100; // Example threshold in pixels

  if (window.scrollY > scrollThreshold && !menutl.reversed()) {
    menutl.reverse();
  }
});
// Toggle function to play or reverse the timeline
function toggle() {
  if (menutl.reversed()) {
    menutl.play();
  } else {
    menutl.reverse();
  }
} 
  


// Function to handle the intersection
function handleIntersection(entries) {
  entries.forEach(entry => {
      const element = entry.target;
      
      if (entry.isIntersecting) {
          if (element.hasAttribute('data-start')) {
              //console.log('Element with data-start is intersecting!');
              
              gsap.to(".single-footer-cta", {
                y: "0",
                opacity: 1,
                duration: 0.3,
                ease: [.86, 0, .07, 1]
              });

              element.classList.add('in-view');
          }
          if (element.hasAttribute('data-stop')) {
              //console.log('Element with data-stop is intersecting!');
              
              // Reverse or reset the animation
              gsap.to(".single-footer-cta", {
                y: "100px", // or whatever value you want to reverse to
                opacity: 0,
                duration: 0.3,
                ease: [.86, 0, .07, 1]
              });

              element.classList.remove('in-view');
          }
      }
  });
}

// Create an intersection observer instance
const ctaobserver = new IntersectionObserver(handleIntersection, {
  root: null, // viewport
  rootMargin: '0px',
  threshold: 0.1 // Trigger when 10% of the element is visible
});

// Target the elements to observe
const targets = document.querySelectorAll('.observed');
targets.forEach(target => ctaobserver.observe(target));
 
// Create a GSAP timeline
const tl = gsap.timeline({ paused: true });

// // Get references to your elements
// const header =  document.querySelector(".menu-slider-items");
// const mtoggle = document.getElementById("nav-pill");
// const mslider = document.querySelector('.menu-wrapper');
// const btnCube = document.querySelector('.menu-slider-items .btn-cube span');

// // Define animations within the timeline
// tl.to(header, { 
//   width: 0,
//   // height: 0,  
//   duration: 2,
//   // visibility:  "hidden",
//   //delay: 0.2,
//   ease: "back.out(1.7)",
//   pointerEvents: "none",
//   opacity: 0,
//   padding: 0,
// })
// tl.to(btnCube, { 
//   width: 0,
//   //height: 0,  
//   duration: 2,
//   //delay: 0.2,
//   ease: "back.out(1.7)",
//   pointerEvents: "none",
//   opacity: 0,
//   padding: 0,
// }, 0)
// .to(mtoggle, {
//   padding: "0 0.5rem 0 0rem",
//   visibility: "visible",
//   duration: 0.4,
//   //delay: 0.2,      
//   width: 'auto'
// }, 0); // Start at the same time as the header animation

// // Configure ScrollTrigger
// ScrollTrigger.create({
//   start: 400,
//   end: () => document.documentElement.scrollHeight - window.innerHeight - 2,
//   onUpdate: (self) => {
//     self.direction === -1 ? tl.reverse() : tl.play() ;
//   },
//   onLeave: () => tl.reverse()
// });
// // Add hover functionality to mtoggle
// // mslider.addEventListener('mouseenter', () => {
// //   tl.reverse();// Reverse the timeline when hovering
// // });
  

// mtoggle.addEventListener('click', () => {
//   tl.reverse(); // Reverse the timeline on click
// });

if (document.querySelector(".meta-line") != null) {  
//line
const target = document.querySelector('.meta-line'); // grab your element however you please
const lineOptions = {
  threshold: 1
}
const callback = (entries) => {
  entries && entries.forEach(entry => {
    if (entry.isIntersecting) {
      target.classList.add("active");
    }
  });
};
const observer = new IntersectionObserver(callback, lineOptions);

observer.observe(target); // start observing our element!    
}


if (document.querySelector("section") != null) {
  const coptions = {
    root: null, // Use the viewport as the root
    //rootMargin: '-200px 0px 0px 0px', // No margin around the root
    //threshold: 0 // Trigger when the top of the element reaches the top of the page
    };
  
    let revealCallback = (entries) => {
      entries.forEach((entry) => {
        let container = entry.target;
        if (entry.isIntersecting) {
          container.classList.add("fadeInUp");    
          //revealObserver.unobserve("section");
          return;
        } 
    
    
      });
    };
  
    let revealObserver = new IntersectionObserver(revealCallback, coptions);
  
    document.querySelectorAll(".animated").forEach((reveal) => {
      revealObserver.observe(reveal); 
    });
  }


  if (document.querySelector(".case-item-wrapper") != null) { 

const caseItems = document.querySelectorAll('.case-item-wrapper');


caseItems.forEach(function(el) {
  const span = el.querySelector('.cases_card_content-mask');
  const grad = el.querySelector('.cases_card_gradient');

  el.addEventListener('mouseenter', function() {
    console.log('enter');
    
 
    gsap.to(grad, {
      yPercent: 10,
      duration: 0.5,
      ease: "power1.inOut",
    });

  
    enter(span);
  });

  el.addEventListener('mouseleave', function() {
    console.log('leave');
    
 
    gsap.to(grad, {
      yPercent: 20,
      duration: 0.5,
      ease: "power1.inOut",
    });

 
    leave(span);
  });
});


function enter(span) {
  gsap.to(span, {
    height: "auto",
    duration: 0.5,
    ease: "power1.inOut",
  });
}

function leave(span) {
  gsap.to(span, {     
    height: 0,
    duration: 0.5,
    ease: "power1.inOut",
  });
}
  }


gsap.utils.toArray(".homepage-mid").forEach((section, i) => {
  const bg = section.querySelector(".bg");
  const img = bg ? bg.querySelector('.p-img') : null;  

  if (img) { // Ensure img exists before applying animation
    gsap.fromTo(img, 
      {
        y: () => section.offsetHeight - img.offsetHeight, 
      }, 
      {      
        scrollTrigger: {
          trigger: section,
          scrub: 0.5,
          invalidateOnRefresh: true
        },
        y: 0,
        ease: "none"
      }
    );
  } 
});


if (document.querySelector(".df-sticky-card") != null) { 
// Ensure that the timeline is properly initialized
let dfCardsTL = gsap.timeline();

// Select all .df-sticky-card elements and set their position
let dfCards = gsap.utils.toArray(".df-sticky-card");
gsap.set(dfCards, { position: "absolute" });

// Create a ScrollTrigger instance to control the animation and updates
ScrollTrigger.create({
  animation: dfCardsTL,
  trigger: ".df-sticky-container",
  start: "top top",
  end: "+=3500", // Adjust this value based on the desired scrollable area
  pin: true,
  scrub: 1,
  onUpdate: (self) => {
    const progress = self.progress;

    // Calculate the current index of .df-sticky-card based on scroll progress
    let currentIndex = Math.floor(dfCards.length * progress);

    // Ensure index is within bounds
    currentIndex = Math.max(0, Math.min(currentIndex, dfCards.length - 1));

    // Update .title-box and .content-box with the data from the current car d
    const currentCard = dfCards[currentIndex];
    const title = currentCard.getAttribute('data-title');     
    const content = currentCard.getAttribute('data-content');

    // Fade in the .title-box and .content-box with GSAP
    const titleBox = document.querySelector('.title-box');
    const contentBox = document.querySelector('.content-box');
    const navItems = document.querySelectorAll('.box-nav-item');

    // Fade in the title-box 
   
 
    // Update text content
    titleBox.textContent = title || ''; // Fallback to empty string if title is not found
    contentBox.textContent = content || ''; // Fallback to empty string if content is not found

    // Add 'active' class to the corresponding .box-nav-item
    navItems.forEach((item, index) => {
      item.classList.toggle('active', index === currentIndex); // Toggle 'active' class based on index
    });
  }
});

// Add animations to the timeline for each .df-sticky-card, except the last one
dfCards.forEach((e, index) => {
  if (index !== dfCards.length - 1) {
    dfCardsTL.to(e, {
      clipPath: "inset(0px 0px 100%)",
      duration: 1 // Duration for the animation, adjust as needed
    });
  } 
});
}  
        
  


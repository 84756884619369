import { Draggable } from '../../js/gsap/Draggable.min.js';
// import { InertiaPlugin } from '../../js/gsap/InertiaPlugin.min.js';

if (document.querySelector(".postSlider") != null) {
  let feedcontainer = document.querySelector(".slider .post-list");

  const options = { 
    root: null,
    threshold: 0,
    rootMargin: "-150px"
  };

  const postSlider = document.querySelector(".postSlider");
  const postObserver = new IntersectionObserver((entries, postObserver) => { 
    entries.forEach(entry => { 
      if (!entry.isIntersecting) {
        return;
      }

      console.log(entry.target);
      gsap.to(".post-list-item header", {
        duration: 0.5, 
        x: 0,
        opacity: 1,
        stagger: 0.1,
        ease: "sine.out", 
       
      });

      postObserver.unobserve(entry.target);
    });
  }, options);
  postObserver.observe(postSlider);
  

  let pressed = false;
  feedcontainer.addEventListener("mousedown", () => {
    pressed = true;
    feedcontainer.style.cursor = "grabbing";
    feedcontainer.classList.add('grabbing');
  });

  feedcontainer.addEventListener("mouseenter", () => {
    feedcontainer.style.cursor = "grab";
  });

  feedcontainer.addEventListener("mouseup", () => {
    feedcontainer.style.cursor = "grab";
    pressed = false;
    feedcontainer.classList.remove('grabbing');
  });

  Draggable.create(".slider .post-list", {
    type: "x",
    edgeResistance: 0.5,
    snap: (val) => Math.round(val / 100) * 100,
    inertia: true,
    allowContextMenu: true,
    onThrowComplete: function() { console.log(this.x); },
    bounds: {
      minX: -document.querySelector(".post-list").offsetWidth + document.querySelector(".slider").offsetWidth,
      maxX: 0
    }
  });
}


 
 